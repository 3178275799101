import React, { useEffect, useState } from 'react'
import { translate } from 'react-i18next'

import ChevronDown from '@interco/icons/build-v4/orangeds/LG/chevron-down'
import Check from '@interco/icons/build-v4/orangeds/LG/check'

import { Country, OpenLang } from './style'

interface IFooterProps {
  t: (values: string) => string;
  theme?: string;
  ptURL: string;
  enURL: string;
  i18n: { language: string };
}

type ListProps = {
  country: string;
  icon?: React.ReactNode;
  urlPage: string;
}

const Language = (props: IFooterProps) => {
  const [ openLanguage, setOpenLanguage ] = useState(false)
  const [ activeLanguage, setActiveLanguage ] = useState(true)
  const [ isIndex, setIsIndex ] = useState(props.i18n.language === 'pt' ? 0 : 1)

  const listLanguage = [
    {
      country: props.t('portuguese'),
      urlPage: props.ptURL,
    },
    {
      country: props.t('inglish'),
      urlPage: props.enURL,
    },
  ]

  const [ isListLanguage, setIsListLanguage ] = useState(listLanguage[isIndex])

  const HandleClickLanguage = (index: number) => {
    setIsIndex(index)
    isIndex === index && setActiveLanguage(true)
  }

  useEffect(() => {
    setIsListLanguage(isListLanguage)
  }, [ isIndex, props.i18n.language, isListLanguage ])

  return (
    <Country
      theme={props.theme}
      className='d-flex mb-4'
    >
      <div className='col-12 position-relative d-flex align-items-center pl-lg-0'>
        <p className='fs-14 lh-17 mb-0 mr-xl-2'>
          {props.t('language')}
        </p>
        <div key={isListLanguage.country} className='position-relative d-flex align-items-center justify-content-start'>
          <span className='fs-16 lh-20 fw-600' onClick={() => setOpenLanguage(!openLanguage)}>
            {isListLanguage.country}
          </span>
          <div
            onClick={() => {
              setOpenLanguage(!openLanguage)
              setActiveLanguage(!activeLanguage)
              HandleClickLanguage(isIndex)
            }}
            className='cursor-pointer'
          >
            <ChevronDown height={24} width={24} color={props.theme === 'dark' ? '#FFFFFF' : '#161616'} />
          </div>
          {openLanguage &&
            <OpenLang>
              {listLanguage.map((item: ListProps, index: number) => (
                <div key={item.country} className='col-12 px-0 d-flex align-items-center justify-content-between list'>
                  <a
                    href={item.urlPage}
                    className='d-flex align-items-center justify-content-between w-100'
                    onClick={() => {
                      setOpenLanguage(!openLanguage)
                      HandleClickLanguage(index)
                    }}
                  >
                    <div className='col-9 col-md-7 px-0 pl-md-1'>
                      <span className={`fs-16 lh-20 fw-600 ml-0 ${activeLanguage && isIndex === index ? 'text-orange--extra' : ''}`}>{item.country}</span>
                    </div>
                    <div className='col-3 col-md-4 pr-0'>
                      <Check height={24} width={24} color={activeLanguage && isIndex === index ? '#ff7a00' : '#FFFFFF'} />
                    </div>

                  </a>
                </div>
              ))}
            </OpenLang>
          }
        </div>
      </div>
    </Country>
  )
}

export default translate('Footer')(Language)
