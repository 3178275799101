import * as URLS from 'config/api/Urls'
import React from 'react'
import Helmet from 'react-helmet'
import { AnswerDataType, FaqTabType } from 'src/components/FaqWithTab/types'
import defaultImage from './../../../assets/images/temps/og-default-image.jpg'

let language: boolean | string | RegExpMatchArray | null = typeof window !== 'undefined' && window.location.pathname.match(/(\/)(\w{2})(\/)/)
language = language ? language[0].replace(/\//g, '') : 'pt-BR'

type metaHeaderProps = {
  title?: string;
  metaDescription?: string;
  canonical: string;
  url: string;
  bodyAttributes?: {};
  ogTitle?: string;
  ogUrl?: string;
  ogDescription?: string;
  ogSiteName?: string;
  ogImage?: string;
  ogImageWidth?: number | string;
  ogImageHeight?: number | string;
  ogType?: string;
  twitterCard?: string;
  twitterSite?: string;
  twitterCreator?: string;
  crazyEggId?: boolean;
  noFollowOption?: boolean;
  noIndexOption?: boolean;
  removeFaqStructureData?: boolean;
  twitterDomain?: string;
  structuredData?: {
    newStructureData?: string;
    faq?: {
      question: string;
      answer: string;
      }[];
    faqTab?: FaqTabType[];
  };
  isClarityScript?: boolean;
  redirectUsa?: string;
  hrefLang?: {
    link: string;
    location: string;
  };
}

type itemProps = {
  question: string;
  answer: string;
}

const defaultEstrutucreData = {
  '@context': 'http://schema.org',
  '@type': 'Website',
  name: 'Inter',
  url: 'https://inter.co/',
  sameAs: [
    'https://www.instagram.com/Interbr/',
    'https://www.linkedin.com/company/inter/',
    'https://www.facebook.com/interbrasil',
    'https://twitter.com/Interbr',
    'https://youtube.com/@interbr',
    'https://www.tiktok.com/@interbr',
  ],
  address: 'Av. Barbacena, 1219 - Santo Agostinho, Belo Horizonte - MG, 30190-131',
  logo: ' https://marketing.bancointer.com.br/comunicacao/e-mail-mkt-institucional/imagens/banco-inter.png',
  founder: 'Rubens Menin Teixeira de Souza',
  foundingDate: '1994-09-18',
  contactPoint: [ {
    '@type': 'ContactPoint',
    telephone: '3003 4070',
    contactType: 'Capitais e regiões metropolitanas',
    contactOption: 'TollFree',
    areaServed: 'BR',
  }, {
    '@type': 'ContactPoint',
    telephone: '0800 940 0007',
    contactType: 'Demais localidades',
    contactOption: 'TollFree',
    areaServed: 'BR',
  }, {
    '@type': 'ContactPoint',
    telephone: '0800 979 7099',
    contactType: 'Pessoa Com Deficiência de fala e audição',
    contactOption: [ 'HearingImpairedSupported', 'TollFree' ],
    areaServed: 'BR',
  }, {
    '@type': 'ContactPoint',
    telephone: '0800 940 9999',
    contactType: 'SAC',
    contactOption: 'TollFree',
    areaServed: 'BR',
  }, {
    '@type': 'ContactPoint',
    telephone: '0800 940 7772',
    contactType: 'Ouvidoria',
    contactOption: 'TollFree',
    areaServed: 'BR',
  } ],
}

function MetaHeader ({ title, metaDescription, canonical, bodyAttributes, url, ogTitle, ogUrl, ogDescription, ogImage, ogImageWidth, ogImageHeight, ogType, twitterCard, twitterCreator, twitterSite, noFollowOption, noIndexOption, crazyEggId, structuredData, isClarityScript, redirectUsa, hrefLang, ogSiteName, removeFaqStructureData, twitterDomain }: metaHeaderProps) {
  const siteUrl = URLS.SITE_BASE_URL
  const seo = {
    url: `${siteUrl}${ogUrl || '/'}`,
    ogTitle: ogTitle || title,
    ogSiteName: ogSiteName || undefined,
    ogDescription: ogDescription || metaDescription,
    ogImage: ogImage || `${defaultImage}`,
    ogType: ogType || 'website',
    ogImageWidth: ogImageWidth || null,
    ogImageHeight: ogImageHeight || null,
    twitterCard: twitterCard || 'summary',
    twitterSite: twitterSite || 'inter.co',
    twitterCreator: twitterCreator || 'inter.co',
    twitterDomain: twitterDomain || 'inter.co',
    crazyEggId,
    noIndexOption,
    noFollowOption,
    ogUrl: url,
    structureData: structuredData?.newStructureData ? JSON.stringify(structuredData.newStructureData) : JSON.stringify(defaultEstrutucreData, null, 2),
  }

  const faqList: AnswerDataType[] = []
  if (structuredData?.faqTab) {
    structuredData.faqTab.forEach((item: FaqTabType) => {
      item.faq.forEach((faqItem: AnswerDataType) => {
        faqList.push(faqItem)
      })
    })
  } else if (structuredData?.faq) {
    faqList.push(...structuredData.faq)
  }

  const faq = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqList.map((item: itemProps) => ({
      '@type': 'Question',
       name: item.question,
       acceptedAnswer: {
         '@type': 'Answer',
         text: item.answer,
       },
   })),
  }

  const tagManagerScript =
    " (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
    " new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
    " j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
    " 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
    " })(window,document,'script','dataLayer','GTM-KKPG');"

  const clarityScript =
    ' (function(c,l,a,r,i,t,y){\n' +
    ' c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};\n' +
    " t=l.createElement(r);t.async=1;t.src='https://www.clarity.ms/tag/'+i;\n" +
    ' y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);\n' +
    " })(window, document, 'clarity', 'script', 'h1tcxricus');"

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: language }} title={title} metaDescription={metaDescription}
        canonical={canonical} bodyAttributes={bodyAttributes}
      >
        <title>{title || 'Inter. Simplifica a vida.'}</title>
        <meta name='description' content={metaDescription} />
        <link rel='canonical' href={canonical} />
        <script id='structuredData' type='application/ld+json'>{seo.structureData}</script>
        <link
          rel='preconnect'
          href='https://fonts.gstatic.com'
          crossOrigin='anonymous'
        />
        <link href='https://fonts.googleapis.com/css?family=Inter:300,400,600&display=swap' rel='preload' as='style' />
        <link
          rel='stylesheet'
          href='https://fonts.googleapis.com/css?family=Inter:300,400,600&display=swap'
          media='print'
          onLoad="this.media='all'"
        />
        <link href='https://fonts.googleapis.com/css2?family=Sora:wght@400;600;700&display=swap' rel='preload' as='style' />
        <link
          rel='stylesheet'
          href='https://fonts.googleapis.com/css2?family=Sora:wght@400;600;700&display=swap'
          media='print'
          onLoad="this.media='all'"
        />
        <meta property='og:url' content={seo.ogUrl} />
        <meta property='og:title' content={seo.ogTitle} />
        <meta property='og:description' content={seo.ogDescription} />
        <meta property='og:site_name' content={seo.ogSiteName} />
        <meta property='og:type' content={seo.ogType} />
        <meta property='og:image' content={seo.ogImage} />
        {seo.ogImageWidth && <meta name='og:image:width' property='og:image:width' content={seo.ogImageWidth} />}
        {seo.ogImageHeight && <meta name='og:image:height' property='og:image:height' content={seo.ogImageHeight} />}
        <meta property='fb:app_id' content={process.env.FACEBOOK_APP_ID} />
        <meta name='twitter:card' property='twitter:card' content={seo.twitterCard} />
        <meta name='twitter:domain' property='twitter:domain' content={seo.twitterDomain} />
        <meta name='twitter:site' property='twitter:site' content={`@${seo.twitterSite}`} />
        <meta name='twitter:creator' property='twitter:creator' content={`@${seo.twitterCreator}`} />
        <meta name='twitter:title' property='twitter:title' content={seo.ogTitle} />
        <meta name='twitter:description' property='twitter:description' content={seo.ogDescription} />
        <meta name='twitter:image' property='twitter:image' content={seo.ogImage} />
        {crazyEggId && <script type='text/javascript' src='https://script.crazyegg.com/pages/scripts/0034/8656.js' async />}
        {noFollowOption && <meta name='robots' content='nofollow' />}
        {noIndexOption && <meta name='robots' content='noindex' />}
        {structuredData && !removeFaqStructureData && <script id='structured-data' type='application/ld+json'>{JSON.stringify(faq)}</script>}
        {isClarityScript && <script type='text/javascript'>{clarityScript}</script>}
        {hrefLang ? <link rel='alternate' href={hrefLang.link} hrefLang={hrefLang.location} /> : ''}
        {hrefLang ? <link rel='alternate' href={url} hrefLang='pt-BR' /> : ''}
        <script type='text/javascript'>{tagManagerScript}</script>
      </Helmet>
      <noscript>
        <iframe
          title='google manager'
          src='https://www.googletagmanager.com/ns.html?id=GTM-KKPG'
          height='0'
          width='0'
          style={{
            display: 'none',
            visibility: 'hidden',
          }}
        />
      </noscript>
    </>
  )
}

MetaHeader.defaultProps = {
  url: URLS.SITE_BASE_URL,
  canonical: '',
  ogTitle: null,
  ogUrl: null,
  ogDescription: null,
  ogImage: null,
}

export default MetaHeader
