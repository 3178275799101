import React from 'react'
import { translate, InjectedTranslateProps } from 'react-i18next'

import FooterPT from './FooterPT'
import FooterEN from './FooterEN'

import { FooterStyle } from './style'

import { IFooterProps } from './types'

const Footer = (props: IFooterProps & InjectedTranslateProps) => {
  let ptURL: string | undefined = props.pagePT
  let enURL: string | undefined = props.pageEN

  const footerPTPRops = {
    ...props,
    ptURL,
    enURL,
  }

  const isPagePT = props.pagePT || props.slug
  const isPageEN = props.pageEN || props.slug

  if (!(props.pagePT || props.pageEN)) {
    ptURL = '/'
    enURL = '/en/'
  } else {
    ptURL = isPagePT || isPageEN
    enURL = isPageEN || isPagePT
  }

  return (
    <>
      <FooterStyle theme={props.theme} className='container-footer'>
        {props.i18n.language === 'pt'
          ? <FooterPT
              {...footerPTPRops}
            />
          : <FooterEN
              {...footerPTPRops}
            />
        }
      </FooterStyle>
    </>
  )
}

Footer.defaultProps = {
  theme: 'light',
}

export default translate('Footer')(Footer)
