// Reference: https://usehooks.com/useLocalStorage/
import { useState } from 'react'
/**
 * Custom hook that creates an item on localStorage
 * @param {*} name
 * @param {*} value
 * @returns [value, setValue]
 */
export default function useLocalStorage (name, value) {
  const [ storedValue, setStoredValue ] = useState(() => {
    try {
      const item = typeof window !== 'undefined' && window.localStorage.getItem(name)
      return item ? JSON.parse(item) : value
    } catch (error) {
      return value
    }
  })

  const setValue = value => {
    try {
      setStoredValue(value)
      typeof window !== 'undefined' && window.localStorage.setItem(name, JSON.stringify(value))
    } catch (error) {
      return value
    }
  }
  return [ storedValue, setValue ]
}
