import React, { useState } from 'react'

export interface IGlobalContext {
  headerColorContrast: boolean;
  scrollTopHomeValue: number;
  scrollHomeMobileValue: number;
  setHeaderColorContrast: (state: boolean) => void;
  setScrollTopHomeValue: (state: number) => void;
  setScrollHomeMobileValue: (state: number) => void;
}

interface IGlobalProviderProvider {
  children: React.ReactNode;
}

// Create a context
export const GlobalContext = React.createContext({} as IGlobalContext)

// Create a provider wrapper component
export const GlobalProvider = ({ children }: IGlobalProviderProvider) => {
  const [ headerColorContrast, setHeaderColorContrast ] = useState(false)
  const [ scrollTopHomeValue, setScrollTopHomeValue ] = useState(0)
  const [ scrollHomeMobileValue, setScrollHomeMobileValue ] = useState(0)
  return (
    <GlobalContext.Provider value={{ headerColorContrast, scrollTopHomeValue, scrollHomeMobileValue, setHeaderColorContrast, setScrollTopHomeValue, setScrollHomeMobileValue }}>
      {children}
    </GlobalContext.Provider>
  )
}
