import i18n from 'i18next'
import { reactI18nextModule } from 'react-i18next'

// PT
import PTHeader from '../../locales/pt/Header.json'
import PTFooter from '../../locales/pt/Footer.json'
import PTHome from '../../locales/pt/Home.json'
import PTComponents from '../../locales/pt/Components.json'
import PTGlobals from '../../locales/pt/Globals.json'
import PT404 from '../../locales/pt/404.json'
import PTForeignerAccess from '../../locales/pt/ForeignerAccess.json'

// EN
import ENHeader from '../../locales/en/Header.json'
import ENFooter from '../../locales/en/Footer.json'
import ENHome from '../../locales/en/Home.json'
import ENComponents from '../../locales/en/Components.json'
import ENGlobals from '../../locales/en/Globals.json'
import EN404 from '../../locales/en/404.json'
import { OpenAccountRightFormFields } from 'src/components/UI/Forms/OpenAccountRightForm/i18n'

const resources = {
  pt: {
    Header: PTHeader,
    Footer: PTFooter,
    Home: PTHome,
    Components: PTComponents,
    Globals: PTGlobals,
    404: PT404,
    ForeignerAccess: PTForeignerAccess,
    OpenAccountRightForm: OpenAccountRightFormFields.pt,
  },
  en: {
    Header: ENHeader,
    Footer: ENFooter,
    Home: ENHome,
    Components: ENComponents,
    Globals: ENGlobals,
    404: EN404,
    OpenAccountRightForm: OpenAccountRightFormFields.en,
  },
}

i18n
  .use(reactI18nextModule)
  .init({
    fallbackLng: 'pt',
    ns: [ 'Header', 'Home', 'Footer', 'Components', 'Globals', 'Success' ],
    defaultNS: 'Globals',

    resources,

    debug: false,

    interpolation: {
      escapeValue: false,
    },

    cache: {
      enabled: true,
    },

    load: 'languageOnly',

    whitelist: [ 'pt', 'en' ],

    react: {
      wait: true,
    },
  })

export default i18n
