import React, { ReactNode } from 'react'
import { translate } from 'react-i18next'

import BureauVeritas from 'src/assets/images/svg/bureau-veritas/BureauVeritas'

type FooterProps = {
  t: (values: string) => ReactNode;
}

const PrivacyCertification = ({ t }: FooterProps) => {
  return (
    <>
      <span className='title-font d-block fs-16 fs-lg-15 fs-xl-16 fw-600 mb-2'>{t('privacy_certification')}</span>
      <div className='d-flex d-lg-block justify-content-start flex-lg-column pb-4 pb-md-0'>
        <div className='col-auto col-lg-12 mb-2 mr-1 pl-0'>
          <BureauVeritas />
        </div>
      </div>
    </>
  )
}

export default translate('Footer')(PrivacyCertification)
