import React from 'react'
/**
 * Modal
 */

export const ModalContext = React.createContext({
  'open-your-account-form': {
    modal: false,
    setModal: (value) => {},
    isSetDataLayer: (value) => value,
  },
  'open-your-account-form-pj': {
    modal: false,
    setModal: (value) => {},
    isSetDataLayer: (value) => value,
    setDataLayerContext: (value) => value,
  },
  'modal-open-inter-black-contact-form': {
    modal: false,
    setModal: (value) => {},
    isSetDataLayer: (value) => value,
  },
  'modal-open-inter-one-contact-form': {
    modal: false,
    setModal: (value) => {},
    isSetDataLayer: (value) => value,
  },
})
