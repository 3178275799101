import utms from 'src/assets/data/utms-adjust/utms.json'

type utmsProps =
  'email' |
  'instagram' |
  'google' |
  'widget' |
  'push' |
  'inapp' |
  'widget_home' |
  'neoapp' |
  'meta' |
  'tiktok' |
  'Influencers'

export function alphaCode (text: string) {
  const code = Object.keys(utms).find((key: string) => key === text.toLowerCase())

  if (code) {
    return utms[code as utmsProps]
  } else {
    return text
  }
}
