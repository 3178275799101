import React from 'react'
import { Link } from 'gatsby'
import { addCustomDataInsideTag } from 'src/shared/helpers'

type gatsbyLinkWrapperProps = {
  dataAttributeName?: string;
  dataAttributeValue?: string;
  children?: React.ReactNode;
  target?: string;
  rel?: string;
  to: string;
  className: string;
  checkLanding: string;
  style: {};
  ariaLabel: string;
  title: string;
  onClick?: () => void;
}

function GatsbyLinkWrapper ({ dataAttributeName, dataAttributeValue, children, to, className, checkLanding, style, ariaLabel, title, onClick }: gatsbyLinkWrapperProps) {
  let fullLink = to
  const hash = fullLink.indexOf('#')
  if (typeof window !== 'undefined') {
    if (hash > -1 && hash < 1) {
      fullLink = `${window.location.pathname}${window.location.search}${fullLink}`
    } else if (checkLanding === 'true' && window.location.pathname.indexOf('landing') > -1) {
      fullLink = `${to}landing`
    } else {
      fullLink = `${fullLink}${window.location.search}`
    }
  }

  const customDataAttribute = (dataAttributeName && dataAttributeValue)
    ? { ...addCustomDataInsideTag(dataAttributeName, dataAttributeValue) }
    : {}

  return (
    to.includes('://')
      ? (
        <a
          {...customDataAttribute}
          href={fullLink}
          className={className}
          style={style}
          aria-label={ariaLabel}
          title={title}
          onClick={onClick}
        >
          {children}
        </a>
      )
      : (
        <Link
          {...customDataAttribute}
          to={fullLink}
          className={className}
          style={style}
          aria-label={ariaLabel}
          title={title}
          onClick={onClick}
        >
          {children}
        </Link>
      )
  )
}

GatsbyLinkWrapper.defaultProps = {
  children: <></>,
  className: '',
  to: '/',
  checkLanding: 'false',
  style: {},
  ariaLabel: '',
  title: '',
}

export default GatsbyLinkWrapper
