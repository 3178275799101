// import { device } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Country = styled.div`

  @keyframes animatedLang {
    from {
      top: 25px;
    }
    to {
      bottom: -20px;
    }
  }

  .lang {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.usa {
      background: #41479b;
    }
  }

  a {
    color: ${grayscale[500]} !important;
  }

  span {
    margin: 0 12px;
  }

  svg {
    fill: none !important;

    &:hover {
      fill: none;
    }   
  } 
`

export const OpenLang = styled.div`
  position: absolute;
  border-radius: 8px;
  width: 140px;
  border: 1px solid ${grayscale[200]};
  padding: 8px;
  top: 30px;
  background: ${white};
  z-index: 1;
  left: 0;
  min-height: 80px;
  box-shadow: 0px 24px 64px -8px rgba(22, 22, 22, 0.16);
  transition: opacity 0.3s ease 0s;
  animation: animatedLang 0.3s ease forwards;

  .list {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
