export const OpenAccountRightFormFields = {
  pt: {
      openYourAccount: 'Abra agora sua Conta Digital',
      inputName: { label: 'Nome', placeholder: 'Digite seu nome', error: 'Por favor, digite seu nome completo' },
      inputPhone: { label: 'Telefone', placeholder: 'Digite seu telefone', error: 'Digite um telefone válido' },
      inputEmail: { label: 'E-mail', placeholder: 'Digite seu e-mail', error: 'Digite um e-mail válido' },
      inputCPF: { label: 'CPF', placeholder: 'Digite seu CPF', error: 'Digite um CPF válido' },
      inputBirthDate: { label: 'Data de Nascimento', placeholder: 'dd/mm/aaaa', error: 'Digite uma data válida' },
      authorization: 'Autorizo o Inter a tratar meus dados pessoais para envio de comunicações sobre seus produtos e serviços e também estou de acordo com a',
      privacyPolicy: 'Política de Privacidade.',
      titlePrivacyPolicy: 'Acessar Política de Privacidade do Inter',
      continue: 'Continuar',
      sending: 'Enviando...',
      alreadyRegistered: 'Já preencheu? ',
      downloadApp: 'Agora baixe o app',
      knowMore: 'Saiba mais sobre a Conta Digital PF',
      receivedYourData: 'Prontinho! Recebemos os seus dados.',
      now: 'Agora',
      downloadOurApp: 'baixe nosso app,',
      andOpenYourAccount: 'e abra sua conta gratuita',
      downloadAppNow: 'Baixar o app agora',
      underAge: 'Vimos que você tem menos de 18 anos.',
      knowOur: 'Conheça nossa',
      kidsAccount: 'conta Kids',
      alsoCompleteDigitalFree: 'que também é completa, digital e gratuita.',
      underPrivacy: 'De acordo com nossa Política de Privacidade, não armazenamos dados de menores de 18 anos sem o consentimento dos responsáveis.',
      sentMessage: 'Abra a câmera do seu celular e aponte para o QR Code acima. É só baixar o app do Inter e clicar em "Abrir conta completa e gratuita". É rápido e fácil!',
      privacyLink: 'https://inter.co/politica-de-privacidade/privacidade',
      nowDownload: 'Baixe o app e vá em <br/> "Abrir uma conta gratuita"',
      pointPhone: 'Ligue a câmera do seu celular e aponte para o QR Code abaixo. Você será redirecionado para a loja de aplicativos. Aí é só baixar o app do Inter e abrir a sua conta.',
      errorTitle: 'Ah não! Ocorreu um erro com a sua solicitação...',
      errorMessage: 'Verifique a sua conexão com a internet e tente novamente.',
      errorLinkMessage: 'Clique aqui',
      errorFinalMessage: ' para voltar.',
      isHaveAnAccount: 'Não tenho conta',
    },
  en:
    {
      openYourAccount: 'Open your digital account',
      inputName: { label: 'Full name', placeholder: 'Type your full name', error: 'Please, type your full name' },
      inputPhone: { label: 'Phone number', placeholder: 'Type your phone number', error: 'Type a valid phone number' },
      inputEmail: { label: 'Email address', placeholder: 'Type your email address', error: 'Type a valid email address' },
      inputCPF: { label: 'CPF', placeholder: 'Type your CPF', error: 'Type a valid CPF' },
      inputBirthDate: { label: 'Date of birth', placeholder: 'dd/mm/aaaa', error: 'Type a valid date of birth' },
      authorization: 'I authorize Inter to process my personal data to send marketing about products and services and I also agree with the',
      privacyPolicy: 'Privacy Policy.',
      titlePrivacyPolicy: 'Access Inter\'s Privacy Policy',
      continue: 'Continue',
      sending: 'Sending...',
      alreadyRegistered: 'Already registered?',
      downloadApp: 'Now download the app',
      knowMore: 'Find out more about the PF Digital Account',
      receivedYourData: 'Thank you for sending your information.',
      now: 'Now',
      downloadOurApp: 'download our app',
      andOpenYourAccount: 'and open your free account',
      downloadAppNow: 'Download App now',
      underAge: 'We\'ve identified that you\'re under 18.',
      knowOur: 'You can open a',
      kidsAccount: 'Kids Account',
      alsoCompleteDigitalFree: 'A free and digital account for your needs.',
      underPrivacy: 'According to our Privacy policy, we do not store minor\'s data without their legal guardians consent.',
      sentMessage: 'Point your phone camera to the QR Code above. Then download the Inter app and finish creating your free account.',
      privacyLink: 'https://inter.co/en/privacy-policy/privacy/',
      nowDownload: 'Download the Inter app and finish creating your free account.',
      pointPhone: 'Point your phone camera to the QR Code below. You will be directed to your phone\'s app store.',
      errorTitle: 'Something went wrong with your request.',
      errorMessage: 'Please check your internet connection and try again.',
      errorLinkMessage: 'Click here',
      errorFinalMessage: ' to go back',
      isHaveAnAccount: 'I don\'t have an account',
    },
}
