import styled from 'styled-components'
import { gray, orange, grayscale } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export default styled.div`

  .social-network {
    border-bottom: 1px solid ${grayscale['200']};

    @media ${device.tablet} {
      border-bottom: none;
    }

    @media ${device.desktopLG} {
      display: block;
    }

    ul {

      &.list-inline {

        @media ${device.tablet} {
          margin-bottom: 0;
          padding-top: 4px;
        }
      }

      li {
        margin-bottom: 15px;

        @media ${device.tablet} {
          margin-bottom: 0;
        }
        
        p {
          display: none;
        }
      
        svg {
          animation: ease-in-out 0.5s all;
          display: inline-block;
          margin: 0 7px;
          width: 25px;

          &:hover{
            fill: ${orange.dark};
          }
            
          @media ${device.mobile} {
            margin-left: 0;
            margin-right: 5px;
          }
        }
      }
    }

    .menu & {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      padding: 20px 15px;
      background-color: ${gray['100']};

      @media ${device.desktopLG} {
        background-color: transparent;
      }

      .list-inline-item {
        padding: 10px 0;
        width: 100%;

        &:nth-child(4), &:nth-child(5) {
          display: none;
        }

        @media ${device.desktopLG} {
          padding: 0 8px;
          width: auto;

            &:nth-child(4), &:nth-child(5) {
            display: inline-block;
          }
        }
      }

      p {
        display: inline-block;

        @media ${device.desktopLG} {
          display: none;
        }
      }
    }

    .title-font {
      font-family: 'Sora';
      height: 20px;
      line-height: 20px;
    }
  }
`
